import { server } from './config'
import client from './client'
// eslint-disable-next-line
export default {
    login (data, config = {}) {
		return client.post(`${server}/api/auth/authenticate`, data, config)
	},
	getPair(){
		return client.get(`${server}/pair`)
	},
	lookup(data){
		return client.get(`${server}/pair/lookup?token=${data.token}`)
	}
}