import React, {useState,useEffect} from "react";
import swal from "sweetalert";
import history from "../../history";
import Authentication from "../../modules/authentication";
import { Button , Grid, Box , Card , InputAdornment, InputBase ,styled ,Typography, Link} from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Pair from "./Pair";
import Buttons from "../Home/Buttons";
  
const Input = styled(InputBase)(({ theme }) => ({
    backgroundColor:"#f0f0f0",
    marginBottom : "2rem",
    padding: '10px 12px',
    borderRadius: 4,
    border: '1px solid #ced4da',
    '& .MuiInputBase-input': {
        position: 'relative',
        fontSize: 16,
        width: '100%',
        height: '24px',
        padding: '0px',
        border:'none',
        transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
        ]),
    },
}));

function useQuery() {
    const { search } = window.location;
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Login = ({setToken}) => {
   const query = useQuery();
   const [loginData, setLoginData] = useState({email:"", password:""});
   const [isPairLogin, setPairLogin ] = useState(false);
   const [isButtonsPanel, setButtonsPanel] = useState(false);
   const [panelPublicId, setPanelPublicId] = useState("");
   useEffect(() => {
        if(localStorage.getItem("@userToken") !== null)
        {
            localStorage.setItem("@userPanel",null);
            history.replace("/")
        }else{
            const panel = query.get("p");
            if(panel){
                localStorage.setItem("@userPanel",panel);
                setPanelPublicId(panel)
                setButtonsPanel(true)
            }else{
                //
                const userPanel = localStorage.getItem("@userPanel") !== null ? localStorage.getItem("@userPanel") : null;
                if(userPanel !== null){
                    setPanelPublicId(userPanel)
                    setButtonsPanel(true)
                }
            }
        }
    },[query])
   
   const handleBlur = (e) => {
      const newLoginData = { ...loginData };
      newLoginData[e.target.name] = e.target.value;
      setLoginData(newLoginData);
   };
  
    const onPressLogin = (e) =>{
        let email = loginData.email;
        let password = loginData.password;
        Authentication.login({email,password})
        .then((isAuth) => {
            if(isAuth){
               const auth = {
                    token: isAuth.token,
                    id: isAuth.user.id,
                    username: isAuth.user.firstName + " " + isAuth.user.lastName
                }
                setToken(auth);
            }else{
                swal("wrong email or password");
            }
        })
    }

    const onLoginWithPair = () =>{
        setPairLogin(true);
    }

    if(isPairLogin){
        return <Pair setToken={setToken} />;
    }

    if(isButtonsPanel){
        return <Buttons location={{
            state:{
                panel: panelPublicId//query.get("p")
            }
        }} />
    }

    return (
        <Box>
            <Grid 
                container 
                spacing={2} 
                justifyContent="center"
                alignItems="center">
                <Grid item sm={12} lg={8} md={6} xs={12}>
                    <h4 className="card-title mb-2 text-center p-2">Sign in your account</h4>
                    <Card sx={{padding: '30px'}}>
                            <Input  
                                placeholder="Email"
                                type="email"
                                name="email"
                                value={loginData.email}
                                fullWidth 
                                onChange={handleBlur} 
                                endAdornment={
                                    <InputAdornment position="end">
                                        <EmailIcon />
                                    </InputAdornment>
                                }
                            />
                            <Input
                                fullWidth 
                                placeholder="Password"
                                variant="outlined"
                                type="password"
                                margin="normal"
                                name="password"
                                value={loginData.password}
                                onChange={handleBlur} 
                                endAdornment={
                                    <InputAdornment position="end">
                                        <LockIcon />
                                    </InputAdornment>
                                }
                            />
                            <Button className="cmd-primary btn-block" variant="contained" onClick={(e)=>onPressLogin(e)}>Login</Button>{' '}
                            <Link  component="button"  onClick={()=> onLoginWithPair()} style={{marginTop: "1rem"}}>Login with pair code</Link>
                            <div style={{textAlign: 'center', marginTop:'1rem'}}>
                                <Typography variant="p" component="p" style={{marginLeft: "1rem", marginRight:"1rem"}}>Go to TRIGGERcmd.com and create an account. If you use Twitter, Facebook, or Google auth, click your name in the upper-right, click Profile, and set a password so you can log into this app.</Typography>
                                <Typography variant="p" component="p"></Typography>
                            </div>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Login;
