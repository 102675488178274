    
import React, {useEffect, useState} from 'react';
import { 
  Button, 
  Grid, 
  Box, 
  Card, 
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import '../../App.css';
import Commands from "../../modules/command";
import moment from "moment";
import history from "../../history";
import { BootstrapInput } from '../styles/BootstrapInput';

const HomeDetail = (props)=> {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({search:""});
  const [sort] = useState([
    { label: 'Name Ascending', value: 0 },
    { label: 'Name Descending', value: 1 },
    { label: 'Created Date Ascending', value: 2 },
    { label: 'Created Date Descending', value: 3 },
  ]);
  const [sortSelect, setSortSelect] = useState(-1);

  useEffect(() => {
    if(localStorage.getItem("@userToken") === null)
    {
        history.replace("/")
    };
    getCommands();
    // eslint-disable-next-line
  },[]);
  
  const getCommands = () =>{
    const computer_id = props.location.state.id;
    Commands.listCommands({computer_id}).then( (response) =>{
        if(response) 
      {
        setData(response);
      }
    })
  };
  
  const habndelInputSearch = (e) => {
    const newSearch = { ...filter };
    newSearch[e.target.name] = e.target.value;
    setFilter(newSearch);
 };
  const onSearch = () => {
    const name = filter.search;
    const computer_id = props.location.state.id;
    Commands.filterCommands({computer_id,name}).then( (response) =>{
      if(response) 
      {
        switch(sortSelect){
          case 0:
            response = sortByName(response)
            break;
          case 1:
            response = sortByName(response).reverse()
            break;
          case 2:
            response = sortByDate(response)
            break;
          case 3:
            response = sortByDate(response).reverse()
            break;
          default:
            //case not validate
            break;
        }
        setData(response);
      }
    })
  }

  const sortByName = (data) =>{
    return data.sort((a, b) =>{
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 0;
    });
  }

  const sortByDate = (data) =>{
    return data.sort((a, b) =>{
      if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
        return 1;
      }
      if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
        return -1;
      }
      return 0;
    });
  }

  const onClear = () =>{
    setFilter({search:""});
    getCommands();
  }

  const onPressTrigger = (item) =>{
    console.log(item);
    const computer = item.computer.name;
    const trigger = item.name;
    const sender = 'PWA app';
    Commands.save({computer,trigger,sender}).then((response)=>{
        console.log(response)
      if(response)
      {
        // alert(response.message)
      }else{
        alert("Could not send trigger")
      }
    })
  }
    return (
    <Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item lg={12} md={12} sm={12} xs={12}>
      <h4 className="card-title mb-1 text-center p-1">Triggers for {props.location.state.name}</h4>
        <Card className="p-3 mb-40">
        <InputLabel shrink  htmlFor="name">Name:</InputLabel>
        <BootstrapInput
                fullWidth 
                placeholder="Name"
                variant="outlined"
                type="Name"
                name="search"
                margin="normal"
                value={filter.search}
                onChange={habndelInputSearch}
                className="mb-2"
                size="small"
              />
              <InputLabel shrink  htmlFor="sort_on">Sort On:</InputLabel>
              <Select
                    id="sort_on"
                    fullWidth
                    sx={{marginBottom:1}}
                    input={<BootstrapInput/>}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={sortSelect}
                    onChange={e => setSortSelect(e.target.value)}
                  >
                     <MenuItem value={-1}>
                      <em>Select</em>
                    </MenuItem>
                    {sort.map((item, i) => {
                      return (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      )
                      })
                    }
                  </Select>
                <div>
                  <Button  variant="contained" color="primary" onClick={()=> onSearch()}>Search</Button>{' '}
                  <Button  variant="outline" onClick={() => onClear()}>Clear</Button>{' '}
                </div>
              <Card className="border border-dark mt-3">
                <TableContainer component={Paper}>
                  <Table stickyHeader >
                    <TableHead>
                      <TableRow>
                          <TableCell align="center">Name</TableCell>
                          <TableCell align="center">Action</TableCell>
                          <TableCell align="center">Runs</TableCell>
                          <TableCell align="center">Voice</TableCell>
                          {/* <TableCell align="center">Created On</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                       {data.map((item, i) => (
                          <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row" align="center">{item.name}</TableCell>
                            <TableCell component="th" scope="row" align="center">
                              <Button sx={{fontSize:"0.6125rem"}} color="success" variant="contained" onClick={() => onPressTrigger(item)}>Trigger</Button>{' '}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">{item.runCount}</TableCell>
                            <TableCell component="th" scope="row" align="center">{item.voice}</TableCell>
                            {/* <TableCell component="th" scope="row" align="center">{moment(item.createdAt).format("MM/DD/YYYY")}</TableCell> */}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
        </Card>
      </Grid>
    </Grid>
    </Box>
  );
}

export default HomeDetail;
