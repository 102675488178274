import { server } from './config'
import client from './client'
// eslint-disable-next-line
export default {
    listComputers(data, config ={}){
        return client.post(`${server}/api/computer/list`,data , config)
   },
   filterComputers(data, config ={}){
    return client.post(`${server}/api/computer/list?name=${data.name}`,data , config)
},
}