import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const userData = localStorage.getItem('@userToken');
    const user = JSON.parse(userData); 
    return user?.id
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    // Set the time that the access token will expire at
    //5minutos
    if(userToken)
    {
        localStorage.setItem('@userToken', JSON.stringify(userToken));
    }else{
        localStorage.removeItem('@userRoute');
        localStorage.removeItem('@routeProps');
        localStorage.removeItem('@userToken');
    }
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  }
}