import axios from 'axios'
const client = axios.create({
    timeout: 5000,
    headers: {'Content-Type': 'application/json'}
})

// add interceptor on http client to add token authentication on every request
client.interceptors.request.use((config) => {
	return new Promise(async (resolve, reject) => {
        let token;
		try {
            token = localStorage.getItem('@userToken')
            if(token){
               let auth = JSON.parse(token);
                if(config.data)
                    config.data.token = `${auth.token}` 
                else config.data = { token : `${auth.token}`}
            }
		} catch (error) {
		}
        // console.log(config)
        resolve(config)
	})
}, (error) => {
	return Promise.reject(error)
})


export default client;
