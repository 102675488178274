import React,{useState, useEffect} from 'react';
import Authentication from "../../modules/authentication";
import { Button, Grid, Box , Card , Typography, Link , CircularProgress} from '@mui/material'

import moment from 'moment'
import Login from './Login';

const Pair = ({setToken}) => {
    const [isLoading , setLoading ] = useState(false);
    const [pairCode, setPairCode] = useState("")
    const [pairToken, setPairToken] = useState("")
    const [isEmailLogin, setEmailLogin ] = useState(false);

    useEffect(()=>{
        async function load() {
            const response  =  await Authentication.getPair();
            // console.log({response})
            if(response){
                setPairCode(response.pairCode)
                setPairToken(response.pairToken)
                recordStartTime();
            }else{
                alert("Something went wrong")
            }

        }
        let timer1 = setInterval(() =>  calculateTime(), 600000);

        // this will clear Timeout
        // when component unmount like in willComponentUnmount
        // and show will not change to true
        load();
        return () => {
          clearInterval(timer1);
        };
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        async function check() {
            // console.log({pairToken})
            const response  =  await Authentication.lookup({token: pairToken});
            if(response && response.token)
            {
                setLoading(true);
                const auth = {
                    token: response.token,
                    id: -1, // user id
                    username: ""
                }
                setToken(auth);
            }
        }
        let timer = setInterval(() =>  check(), 5000);
        return () => {
            clearInterval(timer);
          };
              // eslint-disable-next-line
    },[pairToken])

    const calculateTime = async() =>{
        // We just became active again: recalculate elapsed time based 
        // on what we stored in AsyncStorage when we started.
        const elapsed = await getElapsedTime();
        if(elapsed>=10){
          const response  = await Authentication.getPair();
          // console.log({response})
          if(response){
              setPairCode(response.pairCode)
              setPairToken(response.pairToken)
              recordStartTime();
          }else{
              alert("Something went wrong")
          }
        }
    }

    const getElapsedTime = async () => {
      try {
        const startTime = localStorage.getItem("@start_time");
        const now = moment();
        const duration = moment.duration(now.diff(moment(startTime)));
        return duration.asMinutes();
      } catch (err) {
        // TODO: handle errors from setItem properly
        console.warn(err);
      }
    };

  const recordStartTime = async () => {
      try {
        const now = new Date();
        localStorage.setItem("@start_time", now.toISOString());
      } catch (err) {
        // TODO: handle errors from setItem properly
        console.warn(err);
      }
    };


    const onPressLogin = async ()=>{
        const response  =  await Authentication.lookup({token: pairToken});
        // console.log({response})
        if(response && response.token)
        {
            setLoading(true);
            const auth = {
                token: response.token,
                id: -1, // user id
                username: ""
            }
            setToken(auth);
        }else{
            if(response){
                alert(response.message);
            }
        }
    }

    const onLoginWithEmail = () =>{
        setEmailLogin(true);
    }

    if(isEmailLogin){
        return <Login setToken={setToken} />
    }


    return (
        <Box>
            <Grid 
                container 
                spacing={2} 
                justifyContent="center"
                alignItems="center">
                    <Grid item sm={12} lg={8} md={6} xs={12}>
                        <h4 className="card-title mb-2 text-center p-2">Sign in your account</h4>
                        <Card sx={{padding: '30px'}}>
                            <p style = {{boxSizing: "border-box", border: "1px solid #ced4da", height: "45px", padding: "10px", textAlign: "center", borderRadius:"4px", fontSize:"16px", marginBottom:"2em"}}>{pairCode}</p>
                            <Button disabled={isLoading} className="cmd-primary btn-block" variant="contained" onClick={(e)=>onPressLogin()}>{isLoading ? <CircularProgress size={32} sx={{color: '#fff' }} />: 'Link'}</Button >{' '}
                            <Link component="button" onClick={()=> onLoginWithEmail()} style={{marginTop: "1rem"}}>Login with email</Link>
                            <div style={{textAlign: 'center', marginTop:'1rem'}}>
                                <Typography variant="p" component="p"></Typography>
                                <Typography variant="p" component="p" style={{marginLeft: "1rem", marginRight:"1rem"}}>Within 10 minutes, log into your account at triggercmd.com, click your name in the upper-right, click Pair, and type in this pair code.</Typography>
                            </div>

                        </Card>
                    </Grid>
            </Grid>
        </Box>
    );
    
}

export default Pair;