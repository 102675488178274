import { server } from './config'
import client from './client'
// eslint-disable-next-line
export default {
    listCommands(data, config ={}){
        return client.post(`${server}/api/command/list`,data , config)
   },
   save(data,config = {}){
       console.log(data)
       return client.post(`${server}/api/run/triggerSave`, data, config)
   },
   filterCommands(data, config ={}){
        return client.post(`${server}/api/command/list?name=${data.name}`,data , config)
    },
}