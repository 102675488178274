import React, {useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import logo from "../../../assest/splash.png";
import AccountCircle  from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import { Menu , MenuItem, } from '@mui/material'
import history from "../../../history";

export default function Header({isAuth , setToken}) {

   const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

      
    const onLogout = () => {
      setToken(false);
      setAnchorEl(null);
    };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent" style={{ background: '#257691' }}>
        <Toolbar>
        { isAuth && <div>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={()=>history.replace('/home')}
          >
            <HomeIcon sx={{ color: '#fff' }}/>
          </IconButton>
          </div>
        }
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img src={logo} width="200" height="33" alt=""/>
          </Typography>
      { isAuth &&         <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle sx={{ color: '#fff' }}/>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={onLogout}>Logout</MenuItem>
              </Menu>
            </div>}
            { 
            !isAuth && <div>
                            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle sx={{ color: '#fff' }}/>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={()=>{localStorage.removeItem("@userPanel");window.location.replace('/')}}>Login</MenuItem>
              </Menu>
            </div>
          }
        </Toolbar>
      </AppBar>
    </Box>
  );
}