import Command from '../services/command'

// eslint-disable-next-line
export default {
    async listCommands(data){
		let commnads;
		try{
			commnads = await Command.listCommands(data);
		} catch(error){
			console.log(error)
			commnads = false;
		}
		return commnads ? commnads.data.records : false;
	},
	async save(data){
		let result;
		try {
			result = await Command.save(data)
		} catch (error) {
			console.log(error)
			result = false;
		}
		return result ? result.data : false;
	},
	async filterCommands(data){
		let commnads;
		try{
			commnads = await Command.filterCommands(data);
		} catch(error){
			console.log(error)
			commnads = false;
		}
		return commnads ? commnads.data.records : false;
	},
}