import Authentication from '../services/authentication'
// eslint-disable-next-line
export default {
    
    //create new season
    async login (data) {
		let authData, result;
		try {
			authData = await Authentication.login(data)
			result = authData.data;
		} catch (error) {
			console.log(error)
			result = false;
		}
		return result;
	},
	async getPair(){
		let result;
		try {
			let data = await Authentication.getPair()
			return data.data;
		} catch (error) {
			console.log(error)
			result = false;
		}
		return result;
	},
	async lookup(data){
		let result;
		try {
			let authData = await Authentication.lookup(data)
			return authData.data;
		} catch (error) {
			console.log(error)
			result = false;
		}
		return result;
	},

}