import PanelButtons from '../services/panelButtons'

// eslint-disable-next-line
export default {
    async listButtons(data){
		let buttons;
		try{
			buttons = await PanelButtons.listButtons(data);
		} catch(error){
			console.log(error)
			buttons = false;
		}
		return buttons ? buttons.data.records : false;
	},
	async save(data){
		let result;
		try {
			result = await PanelButtons.save(data)
		} catch (error) {
			// console.log("module.panelButtons.save error:");
			// console.log(error)
			result = error.response;
		}
		return result ? result.data : false;
	},
	async filterButtons(data){
		let buttons;
		try{
			buttons = await PanelButtons.filterButtons(data);
		} catch(error){
			console.log(error)
			buttons = false;
		}
		return buttons ? buttons.data.records : false;
	},
	async listPublicButtons(data){
		let buttons;
		try{
			buttons = await PanelButtons.listPublicButtons(data);
		} catch(error){
			console.log(error)
			buttons = false;
		}
		return buttons ? buttons.data.records : false;
	},
	async savePublic(data){
		let result;
		try {
			result = await PanelButtons.savePublic(data)
		} catch (error) {
			console.log(error)
			result = false;
			// result = error.response;
		}
		return result ? result.data : false;
	},
	async filterPublicButtons(data){
		let buttons;
		try{
			buttons = await PanelButtons.filterPublicButtons(data);
		} catch(error){
			console.log(error)
			buttons = false;
		}
		return buttons ? buttons.data.records : false;
	},
}