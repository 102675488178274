
import React, { Fragment } from "react";

/// Components
import Markup from "./jsx";

/// Style
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/style.css";
import "./css/custom.css";
import "./App.css"

const App = () => {

   return (
      <Fragment>
         <Markup />
      </Fragment>
   );
};

export default App;
