import React from "react";
import history from "../history";
import useToken from './useToken';
/// React router dom
import { Router, Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";

/// Pages
import Home from "../jsx/Home/Home";
import Detail from "../jsx/Home/HomeDetail";
import Login from "../jsx/Auth/Login"
import Panels from "../jsx/Home/MyPanels"
import Buttons from "./Home/Buttons"
import Pair from "../jsx/Auth/Pair"
import Main from "../jsx/Home/Main"
import {Box, Container } from '@mui/material'
import Header from "./layouts/nav/Header";


const Markup = () => {
   const { token, setToken } = useToken();
   const routes = [
		{ url: "", component: Main },
		{ url: "pair" , component: Pair },
		{ url: "home", component: Home },
		{ url: "detail", component: Detail },
		{ url: "panels" , component: Panels },
		{ url: "buttons" , component: Buttons },
   ];

   if(!token){
    return (
		<div>
			<Header />
			<Container  component="main" maxWidth="md">
				<Box  sx={{  marginTop: 4, marginBottom: 4,  }}>
					<Login setToken={setToken} />
				</Box>
			</Container>
		</div>
	)
   }

   return (
			<Router history={history} basename="/">
				<Header isAuth={token !== null} setToken={setToken}/>
				<Container  component="main" maxWidth="md">
					<Box  sx={{  marginTop: 4, marginBottom: 4,  }}>
						<Switch>
							{routes.map((data, i) => (
								<Route
									key={i}
									exact
									path={`/${data.url}`}
									component={data.component}
								/>
							))}
						</Switch>
					</Box>
				</Container>
			</Router>
				
    );
};

export default Markup;
