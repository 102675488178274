import Computer from '../services/computer'

// eslint-disable-next-line
export default {
    async listComputers(){
		let computers;
		try{
			computers = await Computer.listComputers();
		} catch(error){
			console.log(error)
			computers = false;
		}
		return computers ? computers.data.records : false;
	},
	async filterComputers(data){
		let computers;
		try{
			computers = await Computer.filterComputers(data);
		} catch(error){
			console.log(error)
			computers = false;
		}
		return computers ? computers.data.records : false;
	},
}