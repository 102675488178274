    
import React, {useEffect, useState} from 'react';
import { 
    Button, 
    Grid, 
    Box, 
    Card, 
    TableContainer,
    Table,
    TableHead, 
    TableRow,
    TableCell,
    TableBody,
    Paper,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import '../../App.css';
import panelButtons from "../../modules/panelButtons";
import moment from "moment";
// import history from "../../history";
import { BootstrapInput } from '../styles/BootstrapInput';

const MyPanlesDetail = (props)=> {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({search:""});
  const [panelName, setPanelName] = useState("")
  const [sort] = useState([
    { label: 'Name Ascending', value: 0 },
    { label: 'Name Descending', value: 1 },
    { label: 'Created Date Ascending', value: 2 },
    { label: 'Created Date Descending', value: 3 },
  ]);
  const [sortSelect, setSortSelect] = useState(-1);

  useEffect(() => {
    // if(localStorage.getItem("@userToken") === null)
    // {
    //     history.replace("/")
    // };
    getButtons();
    // eslint-disable-next-line
  },[]);
  
  const getButtons = () =>{
    const { id , panel, name } = props.location.state;
    console.log({panel})
    if(!panel)
    {
      setPanelName(name)
      panelButtons.listButtons({panel_id : id}).then( (response) =>{
        if(response) 
        {
          setData(response);
        }
      })
    }else{
      panelButtons.listPublicButtons({panel}).then( (response) =>{
        if(response) 
        {
          setData(response);
          if(response.length > 0){
            setPanelName(response[0].panel.name);
          }
        }
      })
    }
  };
  
  const habndelInputSearch = (e) => {
    const newSearch = { ...filter };
    newSearch[e.target.name] = e.target.value;
    setFilter(newSearch);
 };
  const onSearch = async() => {
    const name = filter.search;
    const { panel, id } = props.location.state;
    const panel_id = id;
    let response = !panel ? await  panelButtons.filterButtons({panel_id,name}) : await panelButtons.filterPublicButtons({panel,name});
    if(response) 
    {
      switch(sortSelect){
        case 0:
          response = sortByName(response)
          break;
        case 1:
          response = sortByName(response).reverse()
          break;
        case 2:
          response = sortByDate(response)
          break;
        case 3:
          response = sortByDate(response).reverse()
          break;
        default:
          //case not validate
          break;
      }
      setData(response);
    }

  }

  const sortByName = (data) =>{
    return data.sort((a, b) =>{
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 0;
    });
  }

  const sortByDate = (data) =>{
    return data.sort((a, b) =>{
      if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
        return 1;
      }
      if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
        return -1;
      }
      return 0;
    });
  }

  const onClear = () =>{
    setFilter({search:""});
    getButtons();
  }

  const onPressTrigger = ({panelName, buttonName, param, useregex, pipetrigger}) =>{
    var { panel } = props.location.state;

    const button = buttonName
    const sender = 'PWA app via panel';
    var params = param

    console.log(JSON.stringify(panel))

    if(!panel){
      panel = panelName
      if (useregex !== true) {
        panelButtons.save({button,panel,params,sender}).then((response)=>{
          console.log(response)
          if(response){
            if(response.message){
              //alert(response.message)
            }
          }else{
            alert("Could not send trigger.  Check your Internet access.")
          }
        })
      } else {
        params = prompt('Enter your parameter:')
        if (params !== null){
          panelButtons.save({button,panel,params,sender}).then((response)=>{
            console.log(response)
            if(response) {
              if(response.message){
                //alert(response.message)
              }
            } else {
              alert("Could not send trigger.  Check your Internet access.")
            }
          })
        }
      }
    }else{
      if (useregex !== true) {
        panelButtons.savePublic({panel,pipetrigger,button,params}).then((response)=>{
          if(!response) {
            alert("Could not send trigger.")
          }
        })
      } else {
        params = prompt('Enter your parameter:')
        if (params !== null){
          panelButtons.savePublic({panel,pipetrigger,button,params}).then((response)=>{
            if(!response) {
              alert("Could not send trigger.")
            }
          })
        }
      }
    }
  }
    return (
    <Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item lg={12} md={12} sm={12} xs={12}>
      <h4 className="card-title mb-1 text-center p-1">Triggers for {panelName}</h4>
        <Card className="p-3 mb-40">
        <InputLabel shrink  htmlFor="name">Name:</InputLabel>
        <BootstrapInput
                fullWidth 
                placeholder="Name"
                variant="outlined"
                type="Name"
                name="search"
                margin="normal"
                value={filter.search}
                onChange={habndelInputSearch}
                className="mb-2"
                size="small"
              />
              <InputLabel shrink  htmlFor="sort_on">Sort On:</InputLabel>
              <Select
                    id="sort_on"
                    fullWidth
                    sx={{marginBottom:1}}
                    input={<BootstrapInput/>}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={sortSelect}
                    onChange={e => setSortSelect(e.target.value)}
                  >
                     <MenuItem value={-1}>
                      <em>Select</em>
                    </MenuItem>
                    {sort.map((item, i) => {
                      return (
                        <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                      )
                      })
                    }
                  </Select>
                <div>
                  <Button  variant="contained" color="primary" onClick={()=> onSearch()}>Search</Button>{' '}
                  <Button  variant="outline" onClick={() => onClear()}>Clear</Button>{' '}
                </div>
              <Card className="border border-dark mt-3">
                <TableContainer component={Paper}>
                  <Table stickyHeader >
                    <TableHead>
                      <TableRow>
                          <TableCell align="center">Name</TableCell>
                          <TableCell align="center">Action</TableCell>
                          {/* <TableCell align="center">Created On</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                       {data.map((item, i) => (
                          <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row" align="center">{item.name}</TableCell>
                            <TableCell component="th" scope="row" align="center" sx={{}}>
                              {((!item.params || item.params === "") && (!item.regexFilter || item.regexFilter === ""))&& <Button sx={{fontSize:"0.6125rem"}} color="success" variant="contained" onClick={() => onPressTrigger({panelName: item.panel.name , buttonName: item.name , useregex: false, pipetrigger: item.trigger})}>Trigger</Button>}
                              { 
                                item.useregex !== true && item.params && item.params !== "" && item.params.split(",").map((btn)=>{
                                  return (<Button sx={{fontSize:"0.6125rem"}} className="mr-1" color="success" variant="contained" onClick={() => onPressTrigger({panelName: item.panel.name , buttonName: item.name , param: btn , useregex: false, pipetrigger: item.trigger})}>{btn}</Button>)
                                })
                              }
                              {(item.useregex === true && item.regexFilter && item.regexFilter !== "")&& <Button sx={{fontSize:"0.6125rem"}} color="success" variant="contained" onClick={() => onPressTrigger({panelName: item.panel.name , buttonName: item.name , useregex: true, pipetrigger: item.trigger})}>Parameter Input</Button>}
                            </TableCell>
                            {/* <TableCell component="th" scope="row" align="center">{moment(item.createdAt).format("MM/DD/YYYY")}</TableCell> */}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
        </Card>
      </Grid>
    </Grid>
    </Box>
  );
}

export default MyPanlesDetail;
