import { server } from './config'
import client from './client'
// eslint-disable-next-line
export default {
    listButtons(data, config ={}){
        return client.post(`${server}/api/panelbutton/list`,data , config)
    },
    save(data,config = {}){
       return client.post(`${server}/api/panel/trigger`, data, config)
    },
    filterButtons(data, config ={}){
        return client.post(`${server}/api/panelbutton/list?name=${data.name}`,data , config)
    },
    listPublicButtons(data, config ={}){
        return client.post(`${server}/publicpanel/list`,data , config)
    },
    savePublic(data,config = {}){
        return client.post(`${server}/publicpanel/trigger`, data, config)
    },
    filterPublicButtons(data, config ={}){
        return client.post(`${server}/publicpanel/list?name=${data.name}`,data , config)
    },
}