import Panels from '../services/panels'
// eslint-disable-next-line
export default {
    async listPanels(){
		let panels;
		try{
			panels = await Panels.listPanels();
		} catch(error){
			console.log(error)
			panels = false;
		}
		return panels ? panels.data.records : false;
	},
    async filterPanels(data){
		let panels;
		try{
			panels = await Panels.filterPanels(data);
		} catch(error){
			console.log(error)
			panels = false;
		}
		return panels ? panels.data.records : false;
	},
}