import { server } from './config'
import client from './client'
// eslint-disable-next-line
export default {
    listPanels(data, config ={}){
        return client.post(`${server}/api/panel/list`,data , config)
   },
   filterPanels(data, config ={}){
        return client.post(`${server}/api/panel/list?name=${data.name}`,data , config)
    },
}