import React, {  useEffect} from "react";
import history from "../../history";
import {
    useLocation
  } from "react-router-dom";
  
function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Main = () => {
    const query = useQuery();
    useEffect(() => {
        const panel = query.get("p");
        if(!panel){
            const userRoute = localStorage.getItem("@userRoute") !== null ? localStorage.getItem("@userRoute") : 'home';
            const routeProps = localStorage.getItem("@routeProps") !== null ? localStorage.getItem("@routeProps") : null;
            if(localStorage.getItem("@userToken") !== null)
            {
                history.replace(`/${userRoute}`, routeProps ? JSON.parse(routeProps) : null);
            }else{
                history.replace("/login")
            }
        }else{
            localStorage.setItem("@userRoute","buttons");
            localStorage.setItem("@routeProps",JSON.stringify({panel}));
            history.replace("/buttons", { panel })
        }
    },[query])
    return (
        <div>{query.get("p")}</div>
    );

}
export default Main;